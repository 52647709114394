<template>
    <div class="component-layout-main">
        <a-layout class="main-layout">
            <a-layout-sider :class="leftMenuVisible">
                <div class="mask" @click="onLeftMenuHide"></div>
                <Menu mode="inline" @onHide="onLeftMenuHide" />
            </a-layout-sider>
            <a-layout>
                <a-layout-header>
                    <Header @onShow="onLeftMenuShow" />
                </a-layout-header>
                <a-layout-content>
                    <router-view />
                    <Service />
                </a-layout-content>
                <a-layout-footer>
                    <Footer />
                </a-layout-footer>
            </a-layout>
            <Fixed />
        </a-layout>
    </div>
</template>

<script>
    import './main.scss';
    import { ref, watch, onBeforeMount } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute, useRouter } from 'vue-router';
    import { getToken } from '@/api-core/api-core';
    import { message } from 'ant-design-vue';

    import Header from '@/components/layouts/header/header.vue';
    import Footer from '@/components/layouts/footer/footer.vue';
    import Service from '@/components/layouts/footer/service.vue';
    import Fixed from '@/components/layouts/fixed/fixed.vue';
    import Menu from '@/components/layouts/menu/menu.vue';

    export default {
        name: 'Main',
        components: {
            Header, Footer, Service, Fixed, Menu
        },
        setup() {
            const store = useStore();

            const onClickLogo = () => {
                window.location.reload();
            };

            const leftMenuVisible = ref('');
            const onLeftMenuShow = () => {
                leftMenuVisible.value = 'show';
            };
            const onLeftMenuHide = () => {
                leftMenuVisible.value = '';
            };

            const route = useRoute();
            const router = useRouter();

            const checkToken = async () => {
                await router.isReady();
                if (store.state.user.token == '' && store.state.user.urlToken == '' && (route.query.token == undefined || route.query.token == '')) {
                    location.href = 'https://www.welove888.com';
                }
                if (route.query.token != undefined && route.query.token != store.state.user.urlToken) {
                    store.dispatch('user/setUrlToken', {urlToken: route.query.token});
                    getToken({token: store.state.user.urlToken})
                        .then(
                            res => {
                                // localStorage.token = res.data.token;
                                store.dispatch('user/setToken', {token: res.data.token});
                                location.href = '/';
                            }, 
                            res => {
                                // localStorage.token = '';
                                store.dispatch('user/setToken', {token: ''});
                            }
                        );
                }
            };

            watch(() => route.query.success, (n, o) => {
                if (n) {
                    router.replace({query: {}});
                    message.success(n);
                }
            });
            watch(() => route.query.error, (n, o) => {
                if (n) {
                    router.replace({query: {}});
                    message.error(n);
                }
            });

            onBeforeMount(() => {
                checkToken();
                store.dispatch('user/member')
                    .then(res => {})
                    .catch(res => {
                        // message.error('重新登入')
                    });
                store.dispatch('car/get');

                store.dispatch('activity/get');
            });

            return {
                onClickLogo, leftMenuVisible, onLeftMenuShow, onLeftMenuHide,
            }
        }
    };
</script>